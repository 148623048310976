import _ from 'lodash';
import enAuth from './enAuth';
import enAlerts from './enAlerts';
import enApiErrors from './enApiErrors';
import enApiSuccess from './enApiSuccess';
import enBilling from './enBilling';
import enCrossLogin from './enCrossLogin';
import enNotes from './enNotes';
import enReports from './enReports';
import enSettings from './enSettings';
import enShare from './enShare';
import enTable from './enTable';
import enTopPages from './enTopPages';
import enKeywords from './enKeywords';
import enTutorial from './enTutorial';
import enGoogleServices from './enGoogleServices';
import enValidationErrors from './enValidationErrors';
import enWhitelabelDomains from './enWhitelabelDomains';
import enOverview from './enOverview';
import enNotifications from './enNotifications';
import enUpgrade from './enUpgrade';

const En = {
    'average-keyword-rank': 'Average Keyword Ranking',
    'export-serp-history-csv': 'Are you sure you want to export this keyword’s SERP History in .CSV?',
    'only-one-type-of-kw': 'Only one type of keywords can be selected to edit local settings',
    'server-error-page-title': 'We\'ll be back shortly',
    'search-projects': 'Search Projects',
    'not-found-page-title': 'This page does not exist',
    'back-to-dashboard': 'Back to dashboard',
    'try-again': 'Try again',
    'try-again-in-5-min': 'Please try again in 5 minutes',
    'access-level-placeholder': 'Select access level',
    'access': 'Access',
    'accept-offer': 'Accept Offer',
    'account-restricted-tooltip': 'Your account is restricted to certain projects. Please contact your team to change this',
    'account-deleted-msg': 'Your profile has been successefully deleted',
    'cancel-account': 'Cancel Account',
    'account': 'Account',
    'add-note': 'Add Note',
    'add_project': 'Add Project',
    'add_word': 'Add',
    'add_word_all': 'Add All',
    'add-profile': 'Add Google {service} Profile',
    'add-domain': 'Add domain',
    'add-project': 'Add Project',
    'add-keywords-plus': 'Add Keywords',
    'add-keywords': 'Add keywords',
    'plus-add-keywords-count': 'Add Keywords ({count})',
    'add-tags': 'Add Tags',
    'add': ' + Add',
    'all-keywords': 'All Keywords',
    'all-subdomains-and-all-pages': 'All subdomains and all pages',
    'get-months-free': 'Get {count} Months free',
    'upgrade-to-annual': 'Upgrade to annual',
    'annual-billing-toggler': '<span>Annual billing</span> <span class="free-month">GET TWO MONTHS FREE</span>',
    'annual-billing': '@:common.annually',
    'annual-saving': 'Annual savings',
    'apply': 'Apply',
    'apply-date-range': 'Apply Date Range',
    'april': 'April',
    'archive-projects': 'Archive Projects',
    'archive-project': 'Archive Project',
    'archived-project-restore-msg': 'Project "{category}" has been restored',
    'archived-projects-restore-msg': 'Project(s) has been restored',
    'archived-projects': 'Archived Projects',
    'associated-projects': 'Associated Projects',
    'connected-projects': 'Connected Projects',
    'at-least-one-tag-msg': 'Please enter at least one tag',
    'invalidate-bulk-string': 'Invalidate bulk string',
    'august': 'August',
    'avg-bounce-rate': 'Avg. Visit Bounce Rate',
    'avg-bounces': 'Avg. Bounces',
    'avg-minutes': 'Avg. Minutes On Site',
    'avg-page-per-visit': 'Avg. Pageviews Per Visit',
    'avg-pageviews': 'Avg. Pageviews',
    'avg-visitors': 'Avg. visitors',
    'back-project-list': 'Back to Project List',
    'back-to-sign-in': 'Back to Sign in',
    'baseline-updated': 'Baseline successfully updated',
    'billed': 'Billed {cycle}',
    'billing-add-new-card': 'Add new Billing card',
    'billing-card': 'Billing card',
    'billing-change-plan': 'Change Plan',
    'billing-change-package': 'Change Package',
    'billing-current-plan': 'Current Plan',
    'billing-current-package': 'Current Package',
    'billing-selected-package': 'Selected Package',
    'billing-load-plan-error': 'Error on loading plan',
    'billing-plan-fetch-error-msg': 'Error on fetch plan info',
    'billing-renew-subscription': 'Renew Subscription',
    'subscription': 'Subscription',
    'data-management': 'Data Management',
    'total': 'Total',
    'total-keywords': 'Total Keywords',
    'archive': 'Archive',
    'best-rank': 'Best Rank',
    'billing-set-as-default': 'Set As Default',
    'billing-set-card-as-default': 'Would you like to set the card **** **** **** {last4} as a main card for Serpbook payments?',
    'keywords-refreshed': 'Refresh Rank and GSC Metrics for {count} {item}',
    'dynamic-keyword': 'Keyword|Keywords',
    'billing-subscription-ending-soon': 'You cancelled your plan. You will be downgraded to the Free plan on:',
    'billing-subscription-expired-on': 'Your plan cancelled on:',
    'billing-subscription-will-renew': 'Your subscription will renew on',
    'billing-add-card': 'Add Card',
    'billing-update-card': 'Update Card',
    'billing': 'Billing',
    'bulk-import-msg-1': 'The tag values could be treated as a keyword (i.e.',
    'bulk-import-msg-2': ' <br/>If you click "Rewrite" the keyword will be written with keyword as "',
    'bulk-import-tooltip': "Add your domains/keywords in the format ‘domain:keyword:tag1,tags’ (one keyword per line)",
    'bulk-import': 'Bulk import',
    'import': 'Import',
    'bulk-tags-example': 'domain.com:keyword1:tag 1, tag 2, tag 3, tag 4',
    'cancel': 'Cancel',
    'cancel-your-subscription': 'Cancel your subscription',
    'cant-find-package': ' Can\'t find the package you need?',
    'categories': 'Projects',
    'category-name-report': 'Project Name (ie. keyword.pdf)',
    'category-name': 'Project name',
    'compare-competitors': 'Compare your Keyword to your competitors domain.',
    'project-not-found': 'Project not found',
    'change': 'change|Change',
    'change-location': 'Change Location',
    'change-tracking-location': 'Change tracking location (eg. ZIP or Postal code)',
    'chart': 'chart',
    'charts': 'Charts',
    'clear': 'Clear',
    'close': 'Close',
    'cname': 'CNAME',
    'columns': 'Columns',
    'count': 'Count',
    'viewkey-company-description': 'ViewKey Company Description',
    'company-link-placeholder': 'Company Link',
    'company-link': 'ViewKey Company Link',
    'company-name': 'ViewKey Company Name',
    'connect-account': 'Connect Account',
    'continue': 'Continue',
    'copy': 'Copy',
    'correct-format-msg': 'Please correct the format of this line',
    'correct-format-url': 'Please correct the format of this url',
    'create-report': 'Create Report',
    'current-password': 'Current Password',
    'current-plan': 'Current Plan',
    'dashboard': 'Dashboard',
    'date-of-creation': 'Date of creation',
    'date': 'Date',
    'december': 'December',
    'delete-modal-header': 'Do you want to delete this',
    'delete-many-modal-header': 'Do you want to delete these {item}?',
    'delete-project': 'Delete Project',
    'delete-projects': 'Delete Projects',
    'delete-keyword-question': 'Do you want to delete keywords?',
    'delete-keyword-note': 'NOTE: Historical data will also be deleted',
    'delete-report-question': 'Do you want to delete Scheduled Report?',
    'delete-user-question': 'Are you sure you want to delete user',
    'redirect-user-question': 'Would you like to open the {page} page in a new tab',
    'delete': 'Delete',
    'deleted-gr-kw': 'Deleted Projects and Keywords',
    'deleted-report-msg': 'Report has been deleted',
    'optional-add-keyword': 'There are no keywords in this parent project, so you can optionally add in keywords now',
    'dentistry-example-2': 'ie. Dentistry On Queen West',
    'dentistry-example': 'e.g. Dentistry On West Queen Street',
    'deselect-all': 'Deselect all',
    'description': 'Description',
    'desktop': 'Desktop',
    'disabled': 'Disabled',
    'disable-master-notice': 'This change removes access for the Master Account.<br><br>Make sure you have another Admin Addon User to manage the account before proceeding.',
    'display': 'Display',
    'domain-with-subdomains': 'Domain with subdomains',
    'domain': 'Domain',
    'domain-site': 'Domain/Site',
    'done': 'Done',
    'download-in': 'Download in {format}',
    'download-suggestions': 'Download Suggestions',
    'download': 'Download',
    'days': 'Days',
    'due-today': 'Due today',
    'e-commerce': 'E-commerce ({amount} Keywords)',
    'edit-avatar': 'Edit avatar',
    'edit-project': 'Edit Project',
    'edit-keywords': 'Edit keywords',
    'edit': 'Edit',
    'email-tip-msg': 'You can enter multiple emails via , (comma)',
    'email': 'Email',
    'empty-validation-length-msg': 'The Project name limit is 100 characters. Please, shorten the Project name.',
    'empty-keyword-search': 'Please enter a search term',
    'enable-white-label': 'Enable White Label Domain',
    'enabled': 'Enabled',
    'encrypted-payment': 'This is a secure 128-bit SSL encrypted payment',
    'enter-least-one-keyword': 'Please enter at least one keyword!',
    'enter-value': 'Enter this value',
    'enter-your-url': 'Please enter your URL (ie. "example.com")',
    'estimate-plan': 'Estimate your plan',
    'exact-url': 'Exact URL',
    'example-url-and-keyword': 'Please enter a URL and keyword!<br />Format: "example.com:keyword',
    'explore': 'Explore',
    'export-all-keywords': 'Export All Keywords',
    'export-favorites-keywords': 'Export Favorites',
    'export-non-favorites-keywords': 'Export Non-Favorites',
    'export-charts': 'Export Charts',
    'export-data': 'Export Data',
    'export-in': 'Export Project in {format}',
    'exporting': 'Exporting {count} keywords',
    'export-keywords': 'Export Keywords',
    'export-project': 'Export Project',
    'export-named-project': 'Export {project}',
    'export-question': 'Export Project in PDF',
    'export-email-send-to-msg': 'Your export will be emailed to <span class="kw-blue">{email}</span> as soon as it is ready for download.',
    'export': 'Export',
    'export-to-csv': 'Export to CSV',
    'export-csv-project-list-width-metrics': 'Export Project List with Metrics',
    'export-csv-all-project-keywords': 'Export All Project Keywords',
    'export-csv-project-list-width-metrics-desc': 'This will export the project list as you see it in the Dashboard view',
    'export-csv-all-project-keywords-desc': 'This will export all the Keyword data in your account',
    'limited-to': '(Limited to 70,000  keywords)',
    'fail-to-init-intercom-service': 'Intercom initializations failed',
    'favorite': 'Favorite',
    'favorites': 'Favorites',
    'february': 'February',
    'footer-action-restricted-on-search': 'This action is not available in global search',
    'filter-by-project': 'Filter by Project',
    'filter': 'Filter',
    'first-added': 'First added',
    'forgot-password': 'Forgot password',
    'friday': 'Friday',
    'no-more-refreshes': 'You have exceeded the daily allowed refreshes (no more refreshes left)!',
    'fav-kw': 'Add Keyword to favorites.',
    'unfav-kw': 'Remove Keyword from favorites.',
    'gathering-data': 'gathering data...',
    'two-months-free': '2 months free',
    'get-viewkeys': 'Get ViewKey | Get ViewKeys',
    'give-feedback': 'Give feedback',
    'gmb-business-name': 'GMB Business Name (optional)',
    'gmb-name': 'GMB Name Tracking',
    'gmb-tracking-name': 'GMB Tracking by Name',
    'google-profile': 'Google {service} Profile',
    'analytics': 'Analytics',
    'upgrade-now': 'Upgrade Now',
    'api-access-header': 'API',
    'upgrade-for-api-access': 'Upgrade to get access to the Rank Tracker API',
    'upgrade-for-scheduled-reports': 'Upgrade to schedule reports',
    'upgrade-for-scheduled-reports-desc': 'Automate your reporting by scheduling all your reports with customized frequencies and recipients.',
    'upgrade-for-white-label-reports': 'Upgrade to get access to the white label reports',
    'upgrade-for-on-demand-updates': 'Upgrade to get access to the on demand updates',
    'upgrade-for-api-access-desc': 'Pull unlimited data from your account with our API. Feed into your own internal tools to drive decisions and dazzle clients.',
    'upgrade-for-google': 'Upgrade to integrate with {service}',
    'upgrade-for-google-looker-studio': 'Upgrade to get access to Looker Studio connector',
    'upgrade-for-google-analytics-desc': 'Connect your Google Analytics account and see how your user traffic for Organic Searches.',
    'upgrade-for-google-search-console-desc-1': 'Connect your Google Search Console account and get',
    'upgrade-for-google-search-console-desc-2': 'Keyword suggestions directly from Google Search Console for the domain you are tracking',
    'upgrade-for-google-search-console-desc-3': 'Additional Keyword metrics in your ranking table - Impressions, CTR and Clicks',
    'upgrade-for-google-looker-studio-desc': 'Once you have connected to Keyword.com’s Looker Studio connector you wil get access to all your data, allowing to to create customized reports for your customers.',
    'google-analytics': 'Google Analytics',
    'ga-last-update': '(Showing user visits for <span style="color: #0074D9">{domain}</span> | Last updated: {date})',
    'search-console': 'Search Console',
    'google-search-console': 'Google Search Console',
    'google-looker-studio': 'Google Looker Studio',
    'google-looker-studio-header': 'Looker Studio (previously known as Google Data Studio)',
    'google-business': 'Google My Business',
    'google-language': 'Language',
    'google-region': 'Regions',
    'select': 'Select',
    'project-with-groups': 'project with groups',
    'project-and-groups': 'project and groups',
    'project-with-groups-description': 'By {type}ing this project the groups will also be {type}ed. Please confirm you still would like to {type}e this project.',
    'separate-with-enter': 'You can split the text into tags with "Enter"',
    'historical-ranking-urls': 'Historical Ranking URLs',
    'project-actions': 'Project Actions',
    'project-keywords': ' {projectName} project ( {count} keywords)',
    'project-name': 'Project Name',
    'project-reporting-currency': 'Project Reporting Currency',
    'project-reporting-currency-info-1': 'Set the currency that you would like your CPC and other keyword metric values to be reported in, for this project.',
    'project-reporting-currency-info-2': 'If no currency is set here, the account default currency will be used. To change the account default currency, go to Settings > Table Settings.',
    'actions': 'Actions',
    'project': 'project',
    'projects': 'Projects',
    'having-trouble': 'Having trouble?',
    'header-single-project': '{name} {type}?',
    'header-text-plural': '{amount} selected projects',
    'help-link': 'Help',
    'help-link-tooltip': 'Click for more information.',
    'hide-url-keywords': 'Hide URL/Keyword',
    'hide': 'Hide',
    'https-detected': 'HTTPs Detected',
    'ignore-google-places': 'Ignore Google Places/Local listings',
    'exclude-from-sov': 'Exclude from Share of Voice',
    'ignore-local-listings': 'Ignore local listings',
    'image-size-msg': 'The file size must be less than 500 KB',
    'in-top': 'In Top {level}',
    'incorrect-password': 'Incorrect ViewKey Password Entered',
    'input-one-keyword-per-line': 'Input one keyword per line',
    'invite-people': 'Invite people to Project',
    'invoice-amount': 'Amount',
    'invoice-download': 'Download',
    'invoice-history': 'Invoice History',
    'invoice-options-field-one': 'PO Number',
    'invoice-options-field-three': 'Contractor',
    'invoice-options-field-two': 'Region',
    'invoice-options-footer': 'Footer',
    'invoice-options-memo': 'Memo',
    'invoice-update-info': 'Update Invoice Info',
    'invoice-view': 'View',
    'view': 'View',
    'no-google-accounts': 'We could not find any Google {service} profiles in this account. Please try again.',
    'january': 'January',
    'july': 'July',
    'june': 'June',
    'keyword-count': 'Keyword count',
    'keyword-was-not-added': 'keyword was not added',
    'keyword-were-duplicate': '{duplicates}/{total} of your keywords were not added because they already exist.',
    'keyword-were-duplicate-update': '{duplicates}/{total} of your keywords were not updated because they will be duplicate.',
    'keywords-delete-msg': 'Keyword(s) has been deleted',
    'keywords-fetching-error': 'Error during the fetching keywords',
    'keywords-first-upper': 'Keywords',
    'keywords-left': 'keywords left',
    'keywords-used': 'keywords used',
    'refreshed-used': 'daily refreshes used',
    'historical-serp': 'Historical SERP',
    'view-serp': 'View SERP',
    'not-available-after-30': 'Not Available After 30 days',
    'we-will-log-out': 'It seems you have changed your master email address. Please log back in with your account to continue.',
    'keywords-restore-msg': 'Keyword(s) has been restored',
    'selected': 'Selected',
    'keywords-succ-added': 'Keywords Successfully Added',
    'keywords-adding-process': 'Adding keywords - {progress} keywords added so far!',
    'keywords-up': 'Keywords Up',
    'keywords-update-succ-msg': 'Keyword(s) successfully updated',
    'keywords': 'Keywords',
    'language': 'Language',
    'last-step': 'The last step, let\'s finalize this!',
    'less-minute': 'less than a minute ago',
    'api-key-copied-msg': 'Api key copied to clipboard',
    'api-key-reset-tooltip': 'Refresh API Token',
    'api-key-reset-question': 'Are you sure you want to refresh your API key?',
    'api-key-reset-note': 'You will be logged out automatically',
    'link-copied-msg': 'ViewKey link copied to clipboard',
    'mindshare-copied-msg': 'Share of Voice table copied to clipboard as .png',
    'loading': 'LOADING...',
    'location': 'Location: {near}',
    'location-simple': 'Location',
    'lock-viewkey': 'Password lock ViewKey',
    'logo-url': 'Logo URL',
    'logo': 'Logo',
    'manage-projects': 'Manage Projects',
    'manage-tags': 'Manage Tags',
    'map-ranking': 'Tracking: Google Maps',
    'march': 'March',
    'may': 'May',
    'mindshare': 'Share of Voice',
    'mindshare-help': '<div class="mouseover-mindshare">This leaderboard table shows you the top 10 sites that have the most equity of clicks in the SERPs.<br/><br/> The Share % is derived from a weighted calculation to estimate the rough equity of clicks in the SERPs.<br/><br/> This is done by multiplying the Keyword’s expected CTR by the search volume.</div>',
    'mobile': 'Mobile',
    'monday': 'Monday',
    'monthly-search': 'Monthly Search Volume',
    'move-keywords': 'Move Keywords',
    'move-keywords-description': 'Select which project or group to move your keywords into. Your keyword history will be retained.',
    'select-project-tooltip': 'Select project or group',
    'monthly-billing': 'Monthly',
    'multiple-emails': 'You can enter multiple emails.',
    'name-lastname': 'Name Lastname',
    'name-or-email': 'Name or email address',
    'name-placeholder': 'Enter a name for your Project',
    'name': 'Name',
    'new-project': 'New Project',
    'new-password': 'New password',
    'new-report': 'New Report',
    'next': 'Next',
    'no-account': 'No account?',
    'no-competitor-ranking': 'We could not find this competitor ranking in the time frame',
    'competitors-keywords-limit-exceed': 'We currently only support up to 100 keywords in a single data view. Please filter some keywords by tags or select a different project',
    'no-matches': 'NO MATCHES',
    'not-associated-msg': 'Not associated with any project',
    'not-found': 'Not Found',
    'november': 'November',
    'october': 'October',
    'of-total': 'of',
    'ok': 'OK',
    'only-some-projects': 'only some projects',
    'optional': 'optional',
    'other-tags': 'Other tags in this Project',
    'override-data': 'Override ViewKey data',
    'override-logo-desc': 'Override ViewKey logo/description',
    'overwrite-message': 'Existing tags will be overwritten for duplicate keywords.',
    'page-breaks-pdf': 'Use Page breaks in PDF Reports',
    'parent-project': 'Parent Project',
    'deleted-project': 'Deleted Project',
    'deleted-project-message': 'This Project was deleted, do you want to create same project again?',
    'password-placeholder': '3-100 Characters',
    'password-required-msg': ' Require a Password for the shared ViewKey',
    'password-required': 'Password required',
    'password-updated': 'Password Updated',
    'password': 'Password',
    'payment-secure-msg': 'Enter your payment details securely',
    'percentage': 'Percentage',
    'pivot-table': 'pivot table',
    'platform': 'Platform',
    'please-add-one-admin': 'There must always be at least 1 Admin User to avoid being locked out of the account entirely.',
    'please-continue-add-keyword': 'Please continue on adding keywords',
    'please-enter-url': 'Please enter website URL',
    'prices-are-based-on': 'Prices based on',
    'print-chart': 'Print Chart',
    'profile_id': 'Profile ID',
    'profile': 'Profile',
    'development': 'Development & Integrations',
    'profile-add-scs-msg': 'Your profile has been successfully added',
    'profile-associated-1': 'Your profile has been',
    'profile-associated-2': 'associated with the selected project',
    'protected-project-error-msg': 'Password protected projects may not resend reports',
    'public-Link': 'Public Link',
    'rank': 'Rank',
    'ranking-dist': 'Ranking Distribution',
    'recent': 'Recent',
    'record-type': 'Record Type',
    'record': 'Record',
    'refresh': 'Refresh',
    'refreshing-many-msg': 'Update started: Your keywords will automatically refresh once the update is complete',
    'refreshing-one-msg': 'Update started: Your keyword will automatically refresh once the update is complete',
    'region': 'Region',
    'remove-named-project': 'Remove {project}',
    'report-title': '{action} Report',
    'reports': 'Reports',
    'reset-public-link': 'Reset Public Link',
    'reset-zoom': 'Reset zoom',
    'restore-ok-msg': 'Restore Cancelled',
    'restore': 'Restore',
    'restored-archived-project-msg': 'Project(s) have been restored from archived',
    'restored-deleted-project-msg': 'Project(s) have been restored from deleted',
    'project-s': 'Project(s)',
    'group-s': 'Group(s)',
    'results': 'No Results | One Result | Results',
    'saturday': 'Saturday',
    'scheduled-report-msg': 'Scheduled report successfully {action}',
    'scheduled-reports': 'Scheduled reports',
    'search-google-region': 'Search Google region',
    'search-results-disclaimer': 'Results are limited to the first 250 entries, please refine your search query.',
    'subscribe': 'Subscribe',
    'keywords-tooltip': 'Enter at least 1 Keyword <br> <br> Keywords with more than 80 characters will not have MS Volume collected',
    'region-tooltip': 'Set the Google region that you want to track',
    'language-tooltip': 'Select language you want to track.',
    'ignore-tooltip': 'To track your domain or Google My Business listing in the local snack pack, make sure to uncheck this option.',
    'tags-tooltip': 'Use Tags to organize and categorize your keywords for easier management and reporting.',
    'location-tooltip': 'You can change the tracking location up to street level.',
    'search-by-keyword': 'search by keyword',
    'search-by-domain-or-url': 'search by domain or URL',
    'search-by-rankingurl': 'search by ranking URL',
    'search-by-ranking-value': 'search by ranking value',
    'search-by-tags': 'search by tags ex. tag1, tag2',
    'search-by-searchengine': 'search by searchengine',
    'search-by-searchengine-placeholder': 'ex. google, google-desktop, google-mobile, google-maps, youtube',
    'search-by-favorite': ' yes/no',
    'search-by-': 'search by ranking URL',
    'search-language': 'Search language',
    'search-location': 'Search Location',
    'search': 'Search',
    'search-type-broad': 'Broad Match',
    'search-type-exact': 'Exact Match',
    'secure-payment': 'Secure credit card payment',
    'see-other-plans': 'See other plans',
    'see-all-results-for': 'See all results for',
    'select-a-language': 'Select a Language',
    'select-a-region': 'Select a Region',
    'select-all': 'Select All',
    'select-project': 'Select a Project',
    'select-projects': 'Select Projects',
    'select-plan': 'Select plan',
    'select-profile-msg': 'Please select a profile',
    'select-project-msg': 'Please select a project before proceeding',
    'select-profile': 'Select a Profile',
    'send-invites': 'Send invites',
    'seo-rankings': 'SEO Rankings',
    'september': 'September',
    'serp-history': 'SERP History',
    'serpbook-will-find-url': 'Keyword.com will find the exact URL entered instead of any ranking domain page',
    'set-password': 'Set Password for the ViewKey',
    'share-link': 'share link',
    'share': 'Share',
    'share-named-project': 'Share {project}',
    'share-of-voice-refresh-message': 'Share of Voice data is currently being updated.',
    'share-of-voice-tooltip': 'To leave these keywords out of your Share of Voice calculation, uncheck this box.',
    'share-of-voice-refresh-message-tooltip': 'Data will be up to date once all keyword data has been scraped. <br/><br/> Additionally, any new additions or changes to the keywords in this project, group will only reflect 24 hours after the addition or change.<br /><br/>To refresh Share of Voice sooner, run an on demand refresh on ALL keywords in the entire project',
    'share-of-voice-header': 'Share of Voice: Top 10 (Google)',
    'ranking-vs-distribution-header': 'Ranking Average vs. Distribution',
    'timeframe': 'Timeframe',
    'comparison-timeframe': 'Comparison Timeframe',
    'timeframe-over': 'Timeframe over',
    'downloaded-on': 'Report Date',
    'shared-access': 'Shared Access',
    'show-latest-records': 'Show latest records',
    'show-ranking-urls': 'SHOW RANKING URLs',
    'show-ranking-url': 'Show Ranking URL',
    'show': 'Show',
    'sign-in': 'Sign in',
    'site': 'Site',
    'site-is-featured': 'Site is in the featured snippet!',
    'in-local-pack': 'Site was found in local pack!',
    'slash-month': '/month',
    'sort-by-asc': 'Sort by ascending order',
    'sort-by-desc': 'Sort by descending order',
    'sort-by-name': 'Sort by name',
    'sort-by': 'Sort by',
    'sort-tables-by': 'Sort tables by',
    'start-trial': 'Start my {trialPeriodDays} FREE Trial',
    'start-value': 'Start Value',
    'start-your-trial': 'Get started with your trial',
    'status': 'Status',
    'group': 'Group',
    'submit': 'Submit',
    'succ-save-msg': 'Changes saved successfully!',
    'success-keywords-were-added-to-favorites': 'The selected keyword(s) has been added to your favorites',
    'success-keywords-were-removed-from-favorites': 'The selected keyword(s) has been removed from your favorites',
    'suggested-keywords': 'Suggested keywords',
    'sunday': 'Sunday',
    'switch-accounts': 'Switch Accounts',
    'tags-update-msg': 'Tags have been updated!',
    'tags': 'tags',
    'there-are-only-yt-maps-kw': 'There are no keywords selected in the data-view. Only Google desktop + mobile keywords are supported',
    'thursday': 'Thursday',
    'timestamp-name': 'Timestamp (ie. SEO Progress {time}.pdf)',
    'total-today': 'Total today',
    'track-a-keyword-down-to-city-level': 'Track a keyword down to city level',
    'track-keyword-down-to-city': 'Track a keyword down to city level.',
    'tuesday': 'Tuesday',
    'tutorial-tags-tooltip': 'Tick this box if you want to add existing tags to your new keywords',
    'txt': 'TXT',
    'type-keywords': 'Type keywords',
    'type-zip-or-city': 'Type ZIP/Postal code or City',
    'type-zip': 'Type ZIP/Postal code',
    'un': 'un',
    'undo': 'Undo',
    'unfavorited': 'Non-favorites',
    'deselect-of-keyword': 'Deselect of keyword',
    'unverified': 'Unverified',
    'update-card': 'Update Card',
    'update-project': 'Update Project',
    'update-to': 'Upgrade to {plan}',
    'update-url': 'Update URL to http(s)://',
    'upgrate-to-plan': 'Upgrade to {plan}',
    'upload': 'Upload',
    'url': 'url',
    'value-found': 'Value found',
    'verified': 'Verified',
    'verify-domain': 'Verify Domain',
    'verify-password': 'Verify password',
    'video-id-msg': 'Please enter Video ID (ie. "Hm-08DIMTT4")',
    'video-id': 'Video ID',
    'video-url': 'Video URL',
    'view-all-projects': 'View all Projects',
    'view-keys': 'ViewKeys',
    'viewkey-company-logo': 'ViewKey Company Logo',
    'viewKey-description': 'ViewKey Description',
    'viewKey-logo': 'ViewKey logo',
    'viewkey': 'ViewKey',
    'viewkey-link': 'ViewKey Link',
    'website-url-incorrect': 'Enter a valid URL',
    'wednesday': 'Wednesday',
    'whitelabel-link': 'Whitelabel link',
    'whitelabel-verification': 'Whitelabel domain verification',
    'whitelabel-viewkey': 'Whitelabel ViewKey',
    'embedded-code': 'Embedded Code',
    'with': 'with',
    'without-serpbook-branding': 'Without Serpbook branding',
    'branding': 'Branding',
    'wrong-email-tip': 'Wrong email. Please check it.',
    'yes': 'Yes',
    'no': 'No',
    'your-plan': 'Your Plan',
    'your-trial-is-set-to-expire-on': 'Your trial is set to expire on',
    'youtube-region': 'Youtube Region',
    'yt-ranking': 'Tracking: YouTube',
    'zip-or-postal-code': 'ZIP/Postal code or city',
    'bihourly-updates-toggler': 'Bihourly updates',
    'monthly-billing-toggler': 'Monthly billing',
    'google-local-maps': 'Google Local Maps',
    'google-organic': 'Google Organic',
    'suggestions-duplicates': 'Some of your keywords were duplicate and therefore not added. Keywords must be unique',
    'suggested-filters': 'Suggested Filters',
    'sort-by-changes': 'Sort by Changes When Sorting',
    'viewkey-not-found': 'ViewKey not found',
    'multiple-domains': 'You are tracking multiple domains',
    'change-report-date': 'Change Report Date',
    'export-csv': 'Export CSV',
    'overview': 'Overview',
    'locations': 'Locations',
    'add-location': 'Add Location',
    'search-engine': 'Search Engine',
    'everything': 'Everything',
    'organic-every': 'Google Organic (everything)',
    'organic-desktop': 'Google Organic (desktop)',
    'organic-mobile': 'Google Organic (mobile)',
    'google-maps': 'Google Maps',
    'manual-gmb-msg': 'Manually added GMB name',
    'manual-gmb': 'To ensure the keyword ranks accurately, please select the corresponding Google My Business (GMB) name from the provided dropdown. Manually entering the GMB name may cause discrepancies between the keyword search results and your GMB listing, potentially resulting in an unranked keyword.',
    'manual-gmb-header': 'Manual GMB name entry detected',
    'timeframe-breakdown-chart': 'In this chart you can compare the Average Trends over a specified period of time broken down by ‘Top x’ SERP buckets. <br/><br/> You can compare by the percentage or number of Keywords over time, and see the Daily Average Rank for those Keywords during that time.',
    'track-broad': 'Track Broad Domain',
    'track-exact': 'Track Exact URL',
    'track-and-ignore': 'Track {page} and ignore all other pages',
    'we-noticed-exact': 'We noticed you have inputted an exact URL, but set tracking options to track broad. How should we track this URL?',
    'we-noticed-broad': 'We noticed you have inputted a broad domain, but have set the tracking options to exact. How should we track this URL?',
    'fav-kw-s': 'Add Keyword to your favorites.',
    'unfav-kw-s': 'Remove Keyword from your favorites.',
    'refreshing-kw': 'Refreshing',
    'for-project': 'For Project',
    'new-tag': 'New Tag',
    'tag-name': 'Tag Name',
    'create-tags': 'Create tag(s)',
    'create-tags-success': 'Tag(s) successfully created',
    'delete-tag': 'Delete Tag',
    'edit-tag': 'Edit Tag',
    'delete-project-tag-question': 'Delete {tagName} Tag?',
    'delete-tag-success': 'Tag successfully deleted',
    'edit-tag-success': 'Tag successfully updated',
    'manage-tags-title': 'Search Tag or Create New Tag:',
    'no-tags': 'No tags to display',
    'create-new-tag': 'Create new tag',
    'vk-brand': 'ViewKey Brand',
    'vk-brand-setting': 'ViewKey Brand Settings',
    'vk-brand-setting-text': 'Customize your ViewKey to fit your brand for all the projects in your account.',
    'account-wide': 'Account Wide',
    'table': 'Table',
    'table-settings': 'Table Settings',
    'project-wide-settings': 'Project Specific',
    'vk-setting': 'ViewKey Project Settings',
    'project-updated': 'Project successfully updated',
    'require-password-vk': 'Require a Password for the shared ViewKey',
    'override-account-vk': 'Override Account-Wide ViewKey Branding',
    'search_dots': 'Search...',
    'start-typing': 'Start typing',
    'billing-plan': 'Billing & My Plan',
    'billing-update-plan': 'Update Plan',
    'card-number': 'Card Number',
    'card-cvc': 'CVC',
    'card-expiry': 'Expiry Date',
    'payment-details': 'Payment Details',
    'invoices': 'Invoices',
    'update': 'Update',
    'update-invoice-detail': 'Update Invoice Details',
    'update-billing-details': 'Update Billing Details',
    'edit-billing-details': 'Edit Billing Details',
    'billing-details-subtitle': 'Company Name & Address',
    'billing-details-description': 'This information will be included on all billing invoices on your account.',
    'required-star': '<span style="color:#0074D9">*</span>',
    'invoice-detail': 'Invoice Details',
    'pdf': 'PDF',
    'cancel-subscription-question': 'Are you sure you want to cancel?',
    'yes-cancel': 'Yes, Cancel',
    'continue-with-plan': 'No, Continue with Plan',
    'pay-price-a-month': 'Pay {price}/month',
    'plan-has-been-canceled': 'Your plan has been cancelled',
    'update-credit-card': 'Update Credit Card',
    'update-payment-method': 'Update Payment Method',
    'migrate-to-stripe-message': 'We have fully migrated over to Stripe payment processing and no longer accept payments through 2Checkout or PayPal. <br> <br> If you are seeying this message it means that your current subscription is still on either 2Checkout or PayPal. To avoid any disruption in services, please update your payment details.',
    'overview-beta': 'Overview (Beta)',
    'selected-keywords-count': '{count} Keywords are selected.',
    'select-all-count-keywords': 'Select all {count} keywords.',
    'keyword-chart-limitation': 'Only 10 charts per project are supported, please reduce the number of charts.',
    'time-frame-over': 'Timeframe Over:',
    'all-keywords-link-text-on-pdf': 'Click here to view the rest of the keywords',
    'contact-us-for-view-key-link': 'Contact us for a ViewKey link to access the Historical Charts for projects with over {count} keywords',
    'generating-report': 'Generating Report...',
    'top-1-7': 'Top 1 - 7',
    'top-8-50': 'Top 8 - 50',
    'top-51-100': 'Top 51 - 100',
    'top-101-100': 'Top 101 - 100',
    'top-101-900': 'Top 101 - 990',
    'non-ranking': 'Non-ranking',
    'daily-avg-rank': 'Daily Avg Rank',
    '30-day-avg-rank': '30-Day Avg Rank',
    'ranking-distribution': 'Ranking Distribution',
    'clear-note': 'Clear Note',
    'all-keywords-are-selected': 'All {count} keywords are selected.',
    'unselect-all': 'Unselect all',
    'api-token': 'API Token',
    'view-documentation': 'View Documentation',
    'download-failed': 'Download failed',
    'report-sent-to-email': 'Report successfully sent to email',
    'download-pdf-file': 'Download PDF file',
    'export-new-pdf-text': 'Try our new PDF report',
    'export-legacy-pdf-text': 'Use legacy PDF report',
    'one-pages-selected-keywords': 'All {count} keywords on this page are selected.',
    'other-pages-selected-keywords': '{count} keywords on other page(s) are selected.',
    'add-new-tag-or-group': 'Add New Tag or Group',
    'add-new-tag': 'Add new Tag',
    'add-a-group': 'Add a Group',
    'add-new-tag-text': 'This will add a Tag to your parent project.',
    'add-group-text': 'This will add a new group to your parent project where you can add more tags',
    'new-group': 'New Group',
    'group-name': 'Group Name',
    'group-placeholder': 'Enter a name for your group',
    'add-group': 'Add Group',
    'indicated-tracking-exact-url': 'Indicated that we are tracking the exact URL',
    'add-new-tag-or-group-new-line': 'Add new tag /<br /> group',
    'refresh-keyword': 'Refresh Keyword',
    'open-in-search-results': 'Open in Search Results',
    'show-chart': 'Show Chart',
    'add-star': 'Add to Favorites',
    'remove-star': 'Remove from Favorites',
    'local-listing': 'Local Listing',
    'keyword': 'Keyword',
    'format-tooltip': 'Indicates the format of the report being sent.',
    'email-status-tooltip': 'Indicates the date the email was sent to the report recipient.',
    'refresh-all-keywords-in-all-projects': 'All the keywords in the selected projects will be refreshed',
    'refresh-all-keywords-in-selected-projects-single': '{count} project will be refreshed.',
    'refresh-all-keywords-in-selected-projects-multiple': '{count} projects will be refreshed.',
    'refresh-is-disabled': 'Refresh Keywords <br><br> Upgrade to paid package to use this feature',
    'refresh-is-disabled-warning': 'Upgrade to paid package to use this feature',
    'duplicate-keywords': 'Duplicate Keywords',
    'duplicate-keywords-description': 'Select which project or group to duplicate your keywords into.',
    'billing-details': 'Billing Details',
    'enter-url': 'Enter URL',
    'protocol': 'http(s)://',
    'duplicate-confirm': 'Duplicate {count} number of Keywords?',
    'confirm': 'Confirm',
    'contact-email': 'Email us',
    'live-chat': 'Live chat',
    'apply-new-date': 'Apply New Date',
    'datepicker-close': 'Close',
    'datepicker-today': 'Today',
    'apply-tag': 'Apply',
    'continue-button': 'Continue',
    'forgot-password-email': 'Enter your email',
    'send-form-placeholder': 'support@keyword.com',
    'url-link-field-placeholder': 'support@keyword.com',
    'example-domain': 'example@domain.com',
    'number-placeholder': '123456789',
    'metrics-range-placeholder': '100',
    'domain-com': 'domain.com',
    'cancel-subscription': 'Cancel subscription',
    'cancel-last-step': 'Last step ...',
    'pricing-title': 'OUR PRICING',
    'back-to-pick-plan': 'Go Back to Pick a Plan',
    'keywords-tracked': 'Keywords tracked',
    'demand-updates': 'On-demand updates',
    'websites-tracked': 'Websites tracked',
    'update-frequency': 'Update frequency',
    'local-gmb-tracking': 'Local GMB tracking',
    'google-maps-tracking': 'Google Maps tracking',
    'mobile-tracking': 'Mobile tracking',
    'unlimited-users': 'Unlimited users',
    'google-analytics-integration': 'Google Analytics integration',
    'google-search-console-integration': 'Google Search Console integration',
    'google-looker-studio-integration': 'Google Looker Studio integration',
    'share-of-voice': 'Share of Voice',
    'branded-reports': 'Branded reports',
    'api-access': 'API access',
    'switch-google-account': ' <span class="switch-google-account-tooltip"> Switch Google Account </span>',
    'white-label-reports': 'White label reports',
    'excludes-taxes': 'All prices are exclusive of applicable taxes.',
    'monthly': '@:common.monthly-short',
    'daily': '@:common.daily',
    'weekly': '@:common.weekly',
    'unlimited': '@:common.unlimited',
    'updates': 'updates',
    'free-trial': '({trialPeriodDays} Free Trial)',
    'last-payment-fail': 'Your last payment failed. Please update your credit card.',
    'keyword-domain': 'keyword.com',
    'package-description': '{count} Keywords with {cycle} Updates',
    'confirm-payment-details': 'Confirm your payment details',
    'card-type': ' Card Type',
    'last-4-digits': 'Last 4 digits',
    'expiration-date': 'Expiration Date',
    'update-plan-modal-title': 'You have selected',
    'not-available-plan-modal-title': 'You cannot downgrade your plan to',
    'currency-tracking-keywords-text': 'Because you are currently tracking more than {count} keywords.',
    'reduce-keywords-count-text': 'You can reduce the keywords you are tracking before downgrading your plan.',
    'show-sov-in-view-key': 'Show Share of Voice Overview in shared ViewKey',
    'logged-in-as': 'Logged in as',
    'logout': 'Logout',
    'contact': 'Contact',
    'help': 'Help',
    'api': 'API Documentation',
    'not-applicable': 'Not Applicable',
    'grank-range': 'The grank max and min may not be  lower then 1 or greater than 100.',
    'welcome-to-keyword': 'Hi! Welcome to Keyword.com!',
    'first-add-keyword': 'First off let’s add some keywords',
    'no-result': 'No results',
    'required-symbol': '*',
    'competition-title': 'Competition',
    'tag-title': 'And tag it with:',
    'keyword-title': 'As a keyword:',
    'single-keyword-title': 'As a single keyword:',
    'more-project': '+ more',
    'last-refreshed': 'Last Refreshed: 11 hours ago (Nov 20th, 2018)',
    'https': 'http(s)://',
    'keyword-to-track': 'Keywords to Track',
    'add-all-keywords': 'Add All Keywords',
    'ranking': 'Ranking',
    'suggest-keywords': 'Suggested Keywords',
    'start-typing-keywords': 'Start Typing Keywords',
    'start-typing-for-suggestions': 'Start typing for suggestions',
    'additional-suggestions': 'for additional suggestions',
    'domain-url': 'domain.com',
    'name-email': 'name@email.com',
    'confirm-password': 'Confirm Password',
    'need-help': 'Need help?',
    'our-mailing-address': 'Our Mailing Address',
    'keyword-llc': 'Keyword LLC',
    'office-street': '80 SW 8th St',
    'office-building': 'Ste 3303',
    'post-office': 'Miami, FL 33130',
    'located-country': 'United States',
    'contact-us': 'Contact us',
    'csv': 'CSV',
    'standard-table-width': 'Standard',
    'extra-wide-table-width': 'Extra Wide',
    'edit-group': 'Edit Group',
    'viewKey-group-settings': 'ViewKey Group Settings',
    'archive-group': 'Archive Group',
    'delete-group': 'Delete Group',
    'update-group': 'Update Group',
    'project-upper': 'Project',
    'share-of-voice': 'Share of Voice',
    'line-chart-help': 'See who took the Top 10 Share of Voice  positions over time compared to you.',
    'free-days': 'Free for {trialPeriodDays} then $',
    'package-monthly': 'monthly',
    'package-annually': 'annual',
    'recaptcha-error-msg': 'Something went wrong, please try again or contact support.',
    'keywords-daily': 'Keywords with Daily Updates',
    'keywords-billed': 'Your card will not be billed for {trialPeriodDays}. You can cancel at any time',
    'terms-and-conditions': 'Terms & Conditions',
    'registration-consent': 'By creating your account, you agree to the',
    'payment-details-need-update': 'Your payment details need updating',
    'payment-details-support-msg': 'Since August 2022, Keyword.com no longer support payments via 2CheckOut or PayPal.',
    'payment-details-fill-msg': 'To access your accounts, please update your payment details.',
    'update-payment-details': 'Update Payment Details',
    'import-data': "Import Data",
    'whats-new': "What's new",
    'tax-type': 'Tax Type',
    'tax-number': 'Tax Number',
    'invalid-tax-number': 'Invalid tax number',
    'invalid-tax-type': 'Invalid tax type',
    'other': 'Other',
    'daily-refreshes-user': 'Daily Refreshes Used',
    'weekly-refreshes-user': 'Weekly Refreshes Used',
    'daily-kw-added': 'Daily KWs Added',
    'cycle-kw-added': '{cycle} KWs Added',
    'cycle-refreshes-user': '{cycle} Refreshes Used',
    'kw-used-on-plan': 'KWs Used on Plan',
    'date-range': 'Data range',
    'today': 'Today',
    'last-week': 'Last week',
    'last-month': 'Last month',
    'last-3-months': "Last 3 months",
    'last-6-months': "Last 6 months",
    'last-year': 'Last year',
    'import-csv-msg': 'Import keywords, current & historic ranking data, projects groups and tags from a CSV.',
    'help-csv': 'Need help formatting your CSV file?',
    'choose-csv': 'Choose the CSV you want to import.',
    'drag-or-drop': 'Drag or drop your file here',
    'import-data-step': 'Step 1: Upload CSV| Step 2: Mapping Data|Step 3: Fix & Finish',
    'something-went-wrong': 'Something went wrong',
    'or': 'or',
    'drop-file-here': 'Drop file here',
    'choose-file': 'Choose File',
    'download-our': 'Download our',
    'pre-formatted': 'pre-formatted CSV template',
    'here-and-follow': ' here and follow the formatting for best results.',
    'match-your-csv': 'Match your CSV fields to Keyword.com fields',
    'what-do-duplicated-keywords': 'What do we do with duplicated keywords?',
    'file-name': 'File Name: ',
    'total-rows': 'Total Rows: ',
    'total-imported-keywords': 'Total  # keywords to be imported: ',
    'existing-data': 'Overwrite existing data',
    'do-not-import-the-data': 'Do not import the data',
    'coupon-code': 'Coupon Code',
    'apply-coupon': 'Apply Coupon',
    'months': {
        14: '14 days',
        30: '1 month',
        92: '3 months',
    },
    '-month': {
        14: '14-days',
        30: '1-month',
        92: '3-months',
    },
    'select-field': 'Select field',
    'file-uploaded-successfully': 'File uploaded successfully',
    'file-validated-successfully': 'File validated successfully',
    'row-validated-successfully': 'Row validated successfully',
    'keyword-field': 'Keyword.com Field',
    'your-field': 'Your Field',
    'sample-data': 'Sample Data',
    'import-keywords': 'Import Keywords',
    'import-keywords-note': 'You are about to import {count} keywords. ',
    'unmatched-keywords': 'Unmatched keywords that need your attention',
    'following-correctly-keywords': 'The following keywords were not mapped correctly. To edit, double click on the field and then hit enter to update. ',
    'importing-process-started': 'Importing process started in background.',
    'importing-process-finished': 'Importing process finished.',
    'importing-process-finished-msg': 'Importing process finished successfully. To see your projects, groups and keywords please refresh page.',
    'import-progress': 'Imported {imported} keywords from {total}.',
    'refresh-now': 'Refresh now',
    'refresh-later': 'Refresh later',
    'account-details': 'Account details',
    'start-subscription': 'Start Subscription',
    'trial-period-rest': 'Your {trialPeriod} free trial expires in {trialPeriodRestDays} days. To upgrade your account',
    'click-here': 'click here.',
    'subscription-details':'Subscription details',
    'kw-tracked-daily':'{keywords} Keywords tracked daily',
    'daily-on-demand-updates':'{keywords} Daily on demand updates',
    'on-demand-updates':'On demand updates',
    'unlimited-websites':'Unlimited websites',
    'local-gmb-tracking':'Local GMB tracking',
    'google-maps-tracking':'Google Maps tracking',
    'mobile-tracking':'Mobile tracking',
    'unlimited-users':'Unlimited users',
    'gsc-integration':'Google Search Console integration',
    'ga-integration':'Google Analytics integration',
    'gls-integration':'Google Looker Studio integration',
    'branded-reports':'Branded reports',
    'Keyword suggestions':'Keyword suggestions',
    'white-label':'White label',
    'coupon':'Coupon',
    'your-subscription':'Your Subscription',
    'package-title':'{title} Package ({cycle} payments)',
    'exclusive-taxes':'** Amount is exclusive of applicable taxes.',
    'note': 'Note',
    'beta': 'Beta',
    'keywords-chart-header': 'Keywords in top positions for the last 30 days',
    'keyword-position-tracking': 'Keyword Position Tracking',
    'start-my-free-trial': 'Start my FREE trial',
    'common': {
        'monthly-short': '/mo',
        'annually-short': '/yr',
        'weekly': 'Weekly',
        'daily': 'Daily',
        'unlimited': 'Unlimited',
        'bi-hourly': 'Bi-hourly',
        'annually': 'Annually',
        'free': 'Free',
        'business': 'Business',
        'enterprise': 'Enterprise',
        'package': 'Package',
        'choose-package': 'Choose @:common.package',
    },
    'devices': 'Devices',
    'quick-actions-dots': 'Quick actions...',
    'contact-support-team': 'Contact support team',
    'speak_to_us': 'Speak to us',
    'most-accurate-rank-tracker-info': 'Did you know Keyword.com is the most accurate rank tracker? <a href="https://44592789.fs1.hubspotusercontent-na1.net/hubfs/44592789/Keyword.com%20Rank%20Tracker%20Accuracy%20Report.pdf" target="_blank" class="link">Read the report</a> before canceling.',
    'newsletter-subscription': "Subscribe to our monthly newsletter for the latest updates, tips & tricks to master Keyword.com and industry news",
    'intent': 'Intent',
    'est-traffic': 'Est. Traffic',
    'current-month': 'Current Month',
    'previous-month': 'Previous Month',
    'organic_traffic_acquisition-last-update': 'Last updated: {date} - Showing user visits for <span style="color: #0074D9">{domain}</span>',
    'total-features': 'Total Features',
    'featured': 'Featured',
    'avg-rank': 'Avg Rank',
    'ranking-keywords': 'Ranking keywords',
    'keyword_last_updated': 'Keyword Last Updated',
    'notifications': 'Notifications',
    'email-notifications': 'Email Notifications',
    'display-visibility-and-est-traffic': 'Display Visibility & Est. Traffic Charts',
    'add-competitor': 'Add Competitor',
    'display-mode': 'Display Mode',
    'show-google-incidents': 'Display Google Search Incidents in Historical Charts',
    'show-google-incidents-tooltip': 'Choose whether to display Google Search Incidents in historical charts. This setting also applies to ViewKey reports.',
    'upgrade-your-custom-subscription': 'Upgrade your custom subscription',
    'increase-number-of-keywords-to': 'Increase number of keywords to',
    'new-pricing': 'New Pricing',
    'custom-subscription-text': 'You are currently subscribed to a <b>{count} keyword package with {cycle} updates</b> at a custom discounted rate of <b>$\{price} per keyword</b> paid yearly.',
    'confirm-upgrade': 'Confirm Upgrade',
    'custom-subscription-hint': 'By clicking ‘Confirm Upgrade,’ you agree to the new price and acknowledge that the payment will be processed using the card we have on file.',
    'custom-subscription-downgrade-hint': 'For <b>downgrades</b> please contact our support team for assistance.',
    'per-year': 'per year',
    'custom-package-description': '{count} keywords with <span>{cycle}</span> updates',
    'reset-on-demand-refresh-limit-with-price': 'Reset on demand refresh limit for <b>${price}</b>',
    'reset-on-demand-refresh-limit': 'Reset on-demand refresh limit',
    'get-count-on-demand-refreshes': 'Get <b>{count} extra on demand refreshes</b> until your next automatic top up.',
    'next-automatic-on-demand-refresh': 'Your next automatic on demand refresh top up is on',
    'reset-your-limit-with-price': 'Reset your limit for ${price}',
    'reset-on-demand-refresh-hint': 'By continuing, you agree to the card on file being charged immediately and that the payment is non-refundable.',
    'broad-domain-tooltip': `Broad Domain: Tracks the first occurrence of the domain in the top 100 SERPs. This is ideal if you want to monitor the overall performance of a website, regardless of specific URLs.
            <br /><br />Exact URL: Tracks the exact URL, including any parameters. This is useful if you need to monitor specific pages or variations of a URL.
            <br /><br />Note: URL parameters will only be displayed in the Ranking Table if you're tracking an exact URL.`,
    'ignore-sub-domains': 'Ignore Sub-Domains',
    'ignore-sub-domains-tooltip': 'If you choose to not “Ignore sub-domains”, all instances of the domain and its sub-domains (e.g., blog.example.com) will be tracked. If you choose to “Ignore sub-domains”, only the main domain (e.g., example.com) will be tracked, and any sub-domains will be excluded from the results.',
    'bulk-add': 'Bulk Add',
    'both': 'Both',
}

export default _.merge(
    En,
    enAuth,
    enAlerts,
    enApiErrors,
    enApiSuccess,
    enBilling,
    enCrossLogin,
    enNotes,
    enReports,
    enSettings,
    enShare,
    enTable,
    enTopPages,
    enKeywords,
    enTutorial,
    enGoogleServices,
    enValidationErrors,
    enWhitelabelDomains,
    enOverview,
    enNotifications,
    enUpgrade
);
